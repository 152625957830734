@import "../../../../styles/mixins";

.formHeader {
	margin-bottom: 3rem;
	align-self: center;
}

.formContainer {
	display: flex;
	flex-direction: column;
}

.formDataContainer {
	background-color: #fafafa;
	padding: 3rem 7rem;
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;

	@include respond(phone) {
		padding: 3rem 4rem;
	}
}

.formDataPadding {
	padding: 0 7rem;
}
