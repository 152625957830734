@import "../../styles/variables";

.modalContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title {
	color: $error;
}

.closeIconContainer {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
}

.horizontalLine {
	align-self: stretch;
	border-top: 1px solid #f0f0f0;
}
