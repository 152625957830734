@import "../../../../styles/mixins";

.formHeader {
	margin-bottom: 3rem;
	align-self: center;
}

.formContainer {
	display: flex;
	flex-direction: column;
}

.formDataContainer {
	background-color: #fafafa;
	padding: 3rem 7rem;
	margin-bottom: 3rem;
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;

	@include respond(phone) {
		padding: 3rem 4rem;
	}
}

.formDataPadding {
	padding: 0 7rem;
}

.cardRow {
	display: flex;
	flex-direction: row;
}

.streetRow {
	display: flex;
	flex-direction: row;

	.street {
		flex: 2;

		input {
			width: 100%;
		}
	}

	.streetNumber {
		flex: 1;
		margin-left: 1rem;

		input {
			width: 100%;
		}
	}
}

.cityRow {
	display: flex;

	.postalCode {
		flex: 1;

		input {
			width: 100%;
		}
	}

	.city {
		flex: 2;
		margin-left: 1rem;

		input {
			width: 100%;
		}
	}
}
