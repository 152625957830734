*,
*::before,
*::after {
	margin: 0px;
	padding: 0px;
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;

	font-size: 62.5%;

	@include respond(tab-land) {
		font-size: 56.25%;
	}

	@include respond(phone) {
		font-size: 56.25%;
	}
}

body {
	background-color: $body-background;
}
