@import "../../styles/mixins";

.container {
	max-width: 50rem;
	margin: 0 2rem;
	align-self: center;
	padding-top: 3rem;
}

.getHairLogo {
	width: 27rem;
	margin-top: 5rem;
	margin-bottom: 8rem;
	align-self: center;

	@include respond(phone) {
		margin-top: 3rem;
		margin-bottom: 5rem;
	}
}

.headerContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 0 6rem 4rem 6rem;
}

.titleContainer {
	display: flex;
	flex-direction: row;
	align-items: center;

	svg {
		margin-right: 2rem;
	}
}

.codeContainer {
	width: 100%;
	border-radius: 1rem;
	padding: 3rem;
	border: 1px solid #f0f0f0;
	background-color: #fafafa;
	margin-top: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	.codeHeader {
		font-size: 1.6rem;
	}

	.code {
		margin-top: 1rem;
		font-size: 2.4rem;
		color: #80c9f4;
		font-weight: bold;
	}
}

.productContainer {
	display: flex;
	flex-direction: row;
	padding: 3rem 6rem;
	border-bottom: 1px solid #f0f0f0;
	border-top: 1px solid #f0f0f0;

	@include respond(phone) {
		flex-direction: column;
		align-items: center;
	}

	.productImage {
		max-width: 14rem;
		width: 100%;
		height: 100%;
		border-radius: 7px;

		@include respond(phone) {
			margin-bottom: 2rem;
		}
	}

	.productDetails {
		margin-left: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include respond(phone) {
			align-items: center;
			text-align: center;
		}
	}
}

.supportContainer {
	background-color: #fafafa;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #80c9f4;
	padding: 3rem 6rem;
	border-bottom-right-radius: 11px;
	border-bottom-left-radius: 11px;
}

.backToProductButton {
	align-self: center;
	margin-bottom: 5rem;
}

.loadingContainer {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
