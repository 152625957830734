@import "variables";
@import "mixins";
@import "utilities";
@import "base";
@import "typography";
@import "common";

@font-face {
	font-family: "Nunito";
	src: url(../assets/fonts/Nunito-Light.ttf) format("truetype");
}

@font-face {
	font-family: "Nunito";
	font-weight: 300;
	src: url(../assets/fonts/Nunito-Light.ttf) format("truetype");
}

@font-face {
	font-family: "Nunito";
	font-weight: 700;
	src: url(../assets/fonts/Nunito-Bold.ttf) format("truetype");
}

@font-face {
	font-family: "Nunito";
	font-weight: 900;
	src: url(../assets/fonts/Nunito-Black.ttf) format("truetype");
}
