@import "../../../styles/mixins";

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.stepContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.stepNumber {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.6rem;
	height: 2.6rem;
	border: 1px solid #80c9f4;
	border-radius: 50%;
	margin-right: 1rem;
	color: #80c9f4;
	font-weight: 900;
}

.stepNumber.active {
	background-color: #80c9f4;
	color: white;
}

.stepName {
	@include respond(phone) {
		display: none;
	}
}
