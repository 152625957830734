.box {
	background-color: white;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
	position: relative;
}

.link {
	color: #80c9f4;
	font-weight: 600;
	text-decoration: none;
}
