@import "../../../styles/variables";

.inputField {
	padding: 0.8rem 1.6rem;
	border: 1px solid #f0f0f0;
	border-radius: 8px;
	outline: none;
	transition: border 0.2s ease-in;
	font-size: 1.6rem;
	background-color: white;
	appearance: none;
}

.inputField:focus {
	border: 1px solid #80c9f4;
}

.focused {
	border: 1px solid #80c9f4;
}

.inputField.error {
	border: 1px solid $error;
	color: $error;
}

.inputField::placeholder {
	color: #adadad;
}
