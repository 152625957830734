@import "../../styles/mixins";

.container {
	max-width: 50rem;
	margin: 0 2rem;
	align-self: center;
	padding-top: 3rem;
}

.headerContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 0 6rem 4rem 6rem;
}

.titleContainer {
	display: flex;
	flex-direction: row;
	align-items: center;

	@include respond(tab-port) {
		flex-direction: column;
	}

	svg {
		margin-right: 2rem;

		@include respond(tab-port) {
			margin-right: 0;
			margin-bottom: 2rem;
		}
	}
}

.codeContainer {
	width: 100%;
	border-radius: 1rem;
	padding: 3rem;
	border: 1px solid #f0f0f0;
	background-color: #fafafa;
	margin-top: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.supportContainer {
	background-color: #fafafa;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #80c9f4;
	padding: 3rem 6rem;
	border-bottom-right-radius: 11px;
	border-bottom-left-radius: 11px;
}

.backToProductButton {
	align-self: center;
	margin-bottom: 5rem;
}
