@import "../../../styles/variables";

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem 4rem;
	background-color: #80c9f4;
	border-radius: 1000px;
	color: white;
	font-weight: 900;
	text-transform: uppercase;
	text-align: center;
	user-select: none;
	cursor: pointer;
	border: none;
	font-size: 1.6rem;
}

.button:focus {
	outline: none;
}

.disabled {
	color: $text-color-primary;
	background-color: #f2f3f4;
	cursor: not-allowed;
}
