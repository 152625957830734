@import "../styles/mixins";

.appContainer {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin: 0 auto;
	max-width: 80rem;
	margin-bottom: 5rem;

	@include respond(phone) {
		max-width: 100vw;
	}
}

.getHairLogo {
	width: 27rem;
	margin-top: 5rem;
	margin-bottom: 8rem;
	align-self: center;

	@include respond(phone) {
		margin-top: 3rem;
		margin-bottom: 5rem;
	}
}

.contentContainer {
	display: flex;
	flex: 1 0;
	flex-direction: row;

	@include respond(tab-port) {
		flex-direction: column;
		align-items: center;
	}
}

.productInfoContainer {
	width: 40%;
	padding-right: 5rem;

	@include respond(tab-port) {
		width: 80%;
		padding: 0 4rem;
	}

	@include respond(phone) {
		width: 100%;
		padding: 0 4rem;
	}
}

.checkoutFormContainer {
	width: 60%;
	padding-left: 5rem;

	@include respond(tab-port) {
		width: 80%;
		padding: 0 4rem;
	}

	@include respond(phone) {
		width: 100%;
		padding: 0 4rem;
		margin-bottom: 4rem;
	}
}

.loadingContainer {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
