.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.price {
	font-weight: 700;
}

.vatLabel {
	font-size: 1.4rem;
	margin-left: 6px;
}
