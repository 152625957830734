.checkbox {
	display: flex;
}

.box {
	display: flex;
	width: 16px;
	height: 16px;
	border-radius: 3px;
	margin-right: 1.2rem;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	margin-top: 3px;
	border: 1px solid #f0f0f0;
	background-color: white;
	transition: background-color 0.2s ease-in;

	svg {
		fill: none;
		padding: 1px;
	}
}

.checkbox input:hover + .box {
	cursor: pointer;
}

.checkbox input:checked + .box {
	background-color: #80c9f4;

	svg {
		fill: #fff;
	}
}

.checkbox .label {
	font-size: 1.6rem;
}
