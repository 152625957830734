.paymentMethodContainer {
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
}

.paymentMethod {
	border: 1px solid #f0f0f0;
	background-color: white;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1rem 1.8rem;
	margin-bottom: 1rem;
	cursor: pointer;
	border-radius: 10px;
	position: relative;

	&.selected {
		border: 1px solid #80c9f4;
	}
}

.paymentMethodIcon {
	width: 45px;
	height: 45px;
	margin-right: 1.5rem;
}

.paymentSelectedIcon {
	position: absolute;
	top: -6px;
	right: -6px;
	background-color: #80c9f4;
	width: 24px;
	height: 24px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	display: none;

	&.selected {
		display: flex;
	}
}
