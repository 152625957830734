$font-family: "Nunito", sans-serif;

body {
	font-family: $font-family;
	color: $text-color-primary;
	font-size: 1.6rem;
	-webkit-font-smoothing: antialiased;
}

input,
select,
textarea,
button {
	font-family: inherit;
}

h1 {
	font-size: 2.8rem;
	font-weight: 900;
	text-transform: uppercase;
}

h2 {
	font-size: 2rem;
	font-weight: 900;
	text-transform: uppercase;
}

h3 {
	font-size: 1.8rem;
	font-weight: 900;
	text-transform: uppercase;
}

.error {
	color: $error;
	font-size: 1.6rem;
}
