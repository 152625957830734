.mb-huge {
	margin-bottom: 5rem;
}

.mt-huge {
	margin-top: 5rem;
}

.mb-large {
	margin-bottom: 4rem;
}

.mt-large {
	margin-top: 4rem;
}

.mb-medium {
	margin-bottom: 3rem;
}

.mt-medium {
	margin-top: 3rem;
}

.mb-small {
	margin-bottom: 2rem;
}

.mt-small {
	margin-top: 2rem;
}

.mb-mini {
	margin-bottom: 1rem;
}

.mt-mini {
	margin-top: 1rem;
}

.mb-micro {
	margin-bottom: 0.5rem;
}

.mt-micro {
	margin-top: 0.5rem;
}
