// MEDIA QUERY MANAGER
/*
0px - 600px: Phone
600px - 900px: Tablet Portrait
900px - 1200px: Tablet Landscape
[1200 - 1800] is where our normal styles apply
1800px+: Big Desktop
$breakpoint argument choices:
phone
tab-port
tab-land
big-desktop
1em = 16px
*/

@mixin respond($breakpoint) {
	@if $breakpoint == phone {
		@media only screen and (max-width: 37.5em) {
			// 600px
			@content;
		}
	} @else if $breakpoint == tab-port {
		@media only screen and (max-width: 56.25em) {
			// 900px
			@content;
		}
	} @else if $breakpoint == tab-land {
		@media only screen and (max-width: 75em) {
			// 1200px
			@content;
		}
	} @else if $breakpoint == big-desktop {
		@media only screen and (min-width: 112.5em) {
			// 1800px
			@content;
		}
	}
}
