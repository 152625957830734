@import "../../styles/mixins";

.priceContainer {
	font-size: 1.4rem;

	@include respond(tab-port) {
		font-size: 1.6rem;
	}

	@include respond(phone) {
		font-size: 1.6rem;
	}
}

.imageContainer {
	width: 100%;

	img {
		width: 100%;
		border-radius: 7px;
	}

	@include respond(tab-port) {
		width: 25rem;
		flex: 0 0 25rem;
		margin-bottom: 2rem;
	}

	@include respond(phone) {
		width: 20rem;
		flex: 0 0 20rem;
		margin-bottom: 2rem;
	}
}

.productDescription {
	line-height: 1.5;
	white-space: pre-wrap;
}

.productInfoContainer {
	display: flex;
	flex-direction: column;
	margin-bottom: 4rem;
}
